<template>
  <div>
    <div class="tw-bg-red-100">
      <ml-container>
        <div class="tw-pb-6 tw-flex tw-items-center">
          <div class="tw-mr-8 tw-w-36 tw-h-36 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-brown">
            <Company />
          </div>
          <div v-if="company.id">
            <h1 class="tw-font-semibold tw-text-lg tw-text-black tw-leading-relaxed tw-mb-2">{{ company.name }}</h1>
            <p class="tw-mb-0 tw-text-sm tw-text-gray-n3 tw-leading-relaxed">
              {{ $t('vendor.data.owner') }} {{ company.owner_first_name }} {{ company.owner_last_name }}
            </p>
          </div>
        </div>
      </ml-container>
    </div>
    <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
      <spinner v-if="loading && !company.id"></spinner>
      <form v-if="company.id" ref="form" novalidate="false" @submit.prevent="onSubmit" @change="validateForm()" method="POST">
        <ml-grid>
          <div class="tw-col-span-4 tw-space-y-8">
            <div class="tw-border tw-border-gray-n1 tw-py-6 tw-px-4 tw-rounded-xl tw-shadow">
              <h3 class="tw-font-semibold tw-text-lg tw-text-black tw-leading-relaxed">{{ $t('vendor.data.company_info') }}</h3>
              <div class="tw-mt-4">
                <ml-label required for="company_name">{{ $t('vendor.data.company_name') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.name" id="company_name" name="company_name" required/>
                  <ml-error-msg :msg="errors['name']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label required for="owner_f_name">{{ $t('vendor.data.owner_first_name') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.owner_first_name" id="owner_f_name" name="owner_f_name" required/>
                  <ml-error-msg :msg="errors['owner_first_name']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label required for="owner_l_name">{{ $t('vendor.data.owner_last_name') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.owner_last_name" id="owner_l_name" name="owner_l_name" required/>
                  <ml-error-msg :msg="errors['owner_last_name']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <slug-widget v-if="company" :url="url" subdirectory="showcasework"
                             :title="company.slug ? company.slug:company.name"
                             @slug-changed="updateSlug"
                             @slug-reset="resetSlug"
                             @slug-save="saveSlug"
                ></slug-widget>
              </div>
            </div>
            <div class="tw-border tw-border-gray-n1 tw-py-6 tw-px-4 tw-rounded-xl tw-shadow">
              <h3 class="tw-font-semibold tw-text-lg tw-text-black tw-leading-relaxed">{{ $t('vendor.data.social_media_links') }}</h3>
              <div class="tw-mt-4">
                <ml-label for="instagram_link">{{ $t('vendor.data.instagram') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.instagram_link" id="instagram_link" name="instagram_link" required/>
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label for="facebook_link">{{ $t('vendor.data.facebook') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.facebook_link" id="facebook_link" name="facebook_link" required/>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-col-span-4">
            <div class="tw-border tw-border-gray-n1 tw-py-6 tw-px-4 tw-rounded-xl tw-shadow">
              <h3 class="tw-font-semibold tw-text-lg tw-text-black tw-leading-relaxed">{{ $t('vendor.data.address_contact') }}</h3>
              <div class="tw-mt-4">
                <ml-label required for="address_1">{{ $t('vendor.data.address') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.address1"
                  :placeholder="address1_placeholder" id="address_1" name="address_1" required/>
                  <ml-error-msg :msg="errors['address1']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.address2"
                  :placeholder="address2_placeholder"  id="address_2" name="address_2" required/>
                  <ml-error-msg :msg="errors['address2']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label required for="zip">{{ $t('vendor.data.zip') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.zip" id="zip" name="zip"  required/>
                  <ml-error-msg :msg="errors['zip']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label required>{{ $t('vendor.data.city') }}</ml-label>
                <div class="tw-mt-2">
                  <v-autocomplete
                      class="text-caption remove-bg-fix"
                      dense
                      v-model="selectedCities"
                      :items="cities"
                      :search-input.sync="searchCities"
                      :hide-no-data="!searchCities"
                      :loading="loadingCities"
                      :hint="this.company.state.name"
                      :persistent-hint="false"
                      append-icon=""
                      :rules="[rules.required]"
                      :clearable="true"
                      :hide-selected="false"
                      :deletable-chips="true"
                      @click="clearAreas"
                      color="#2d2d2d"
                      :no-filter="true"
                      :cache-items="false"
                      :placeholder="city_placeholder"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.description"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <template v-slot:selection="data">
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.description"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <ml-error-msg :msg="errors['city']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label required for="phone">{{ $t('vendor.data.phone') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.phone" id="phone" name="phone" required/>
                  <ml-error-msg :msg="errors['phone']" />
                </div>
              </div>
              <div class="tw-mt-4">
                <ml-label for="tin">{{ $t('vendor.data.tin') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="company.tin" id="tin" name="tin" required/>
                  <ml-error-msg :msg="errors['tin']" />
                </div>
              </div>
            </div>
          </div>
        </ml-grid>
        <div class="tw-mt-4">
          <button type="submit"
                    :disabled="loading"
                  class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
            {{ $t('general.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import slugWidget from '../../../components/SlugWidget.vue';
import Slug from 'slug';
import MlContainer from "@/components/layout/MlContainer";
import Company from "@/components/special-icons/Company";
import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import i18n from '@/i18n'

export default {
  middleware: ['auth', 'vendor'],
  components: {
    MlErrorMsg,
    MlInputText,
    MlLabel,
    MlGrid,
    Company,
    MlContainer,
    slugWidget
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  data() {
    return {
      address1_placeholder: i18n.t('vendor.data.address1_placeholder'),
      address2_placeholder: i18n.t('vendor.data.address2_placeholder'),
      city_placeholder: i18n.t('vendor.data.city'),
      edit: false,
      valid: true,
      errors: [],
      lazy: true,
      company: {
        id: null,
        slug: ''
      },
      newlyweds: [],
      countries: [],
      searchCities: null,
      loadingCities: false,
      cities: [],
      loading: false,
      item: {
        event_id: null,
        type_id: null,
        first_name: '',
        last_name: ''
      },
      selectedFile: null,
      file: null,
      rules: {
        required: value => !!value || 'Required.',
        zip: v => /^[0-9]{5}([- ]?[0-9]{4})?$/.test(v) || 'U.S. postal code required (ZIP+4 format)'
      },
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      // Google Maps API
      location: '',
      searchResults: [],
      searchResults1: null,
      service: null,
      filterArea: {},
      filterCountry: [],
      selectedCities: [],
      searchGoogle: false
      // END
    }
  },
  mounted() {
    this.fetchCountries();
    this.getCompany();

  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    url() {
      var url = window.location.origin;
      return url;
    },
    slug() {
      return Slug(this.company.name);
    }
  },
  watch: {
    selectedCities: function () {

      console.log("selectedCities");
      if (!this.selectedCities || this.selectedCities === null) {
        this.filterCountry = [];
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        google_place_id: this.selectedCities.id

      };
      if (!Number.isInteger(this.selectedCities.id)) this.fetchAreas(this.filterArea);


    },
    searchCities(val) {

      if (this.searchCities == null || this.searchCities.length < 3) {
        return
      }

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);

    }
  },
  methods: {
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

        if (!this.company.name) {
          label = "Name required.";
          this.errors.push(label);
          this.errors["name"] = label;
        }

        if (!this.company.owner_first_name) {
          label = "First name required.";
          this.errors.push(label);
          this.errors["owner_first_name"] = label;
        }

        if (!this.company.owner_last_name) {
          label = "Last name required.";
          this.errors.push(label);
          this.errors["owner_last_name"] = label;
        }

        if (!this.company.address1) {
          label = "Address1 required.";
          this.errors.push(label);
          this.errors["address1"] = label;
        }

        /*
        if (!this.company.address2) {
          label = "Address2 required.";
          this.errors.push(label);
          this.errors["address2"] = label;
        }
        */

        if (!this.company.city && !this.company.state) {
          label = "City required.";
          this.errors.push(label);
          this.errors["city"] = label;
        }

        if (!this.company.zip) {
          label = "Zip required.";
          this.errors.push(label);
          this.errors["zip"] = label;
        }else if(!this.validZip(this.company.zip)){
            label = "U.S. postal code required (ZIP+4 format).";
            this.errors.push(label);
            this.errors["zip"] = label;
        }


        if (!this.company.phone) {
          label = "Phone required.";
          this.errors.push(label);
          this.errors["phone"] = label;
        }else if(!this.validPhone(this.company.phone)){
            label = "U.S. phone format required (E.g (308)-135-7895 or 308-135-7895 or 308135-7895 or 3081357895).";
            this.errors.push(label);
            this.errors["phone"] = label;
        }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    validZip: function (v) {
      var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(v);
    },
    validPhone: function (v) {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return re.test(v);
    },
    onSubmit(){
      if(this.validateForm()) this.updateItem();
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      //this.searchResults1 = predictions;
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));

    },
    updateSlug: function (val) {
      this.company.slug = val;
    },
    saveSlug: function (val) {
      //console.log(val);
      this.company.slug = val;
      this.updateItem();
    },
    resetSlug() {
      this.company.slug = null;
    },
    async updateItem() {

      if(!this.validateForm()){
        return;
      }

     // await this.$refs.form.validate();
      if (!this.valid) return;

      this.loading = true;

      let uri = '/api/vendor/companies/' + this.company.id;
      await this.$http.patch(uri, this.company)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
            //this.$router.push({ name: 'vendors.dashboard' })
          }).catch(error => {
            console.log('Error update data: ' + error)
            this.loading = false;
          });
      this.loading = false;
    },
    getCompany() {
      this.loading = true;
      //let uri = '/api/events/' + this.$route.params.id;
      //let uri = '/api/events/1';
      let uri = '/api/vendor/companies';
      this.$http.get(uri)
          .then(({data}) => {
            this.company = data.data,
            this.cities = this.cities.concat(data.data.city)
            this.selectedCities = this.company.city;
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false,
      )

    },
    fetchCountries() {
      this.loading = true;
      this.$http.get('api/countries')
          .then(({data}) => {
            this.countries = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCities(query) {

      const params = {
        "filter[name]": query,
        "filter[country_id]": this.company.countries,
        "page": this.$route.query.page
      };

      this.loadingCities = true;
      this.$http.get('api/cities', {params})
          .then(({data}) => {
            this.cities = data.data
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCities = false
          )

    },
    async fetchAreas(val) {
      console.log("fetchAreas...");
      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;

      const params = {
        "filter[name]": query.city_name,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      await this.$http.get('api/areas', {params})
          .then(({data}) => {
            this.cities = data.data;
            this.selectedCities = this.cities[0];
            this.company.state = this.cities[0].country;
            this.company.city = this.cities[0];
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          });

      //this.company.state.id = this.cities.map(a => a.country_id);


    },
  }
}
</script>
