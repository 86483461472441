<style scoped>
  .slug-widget {
    /*
    display: flex;
    justify-content: flex-start;
    align-items: center;
    */
  }
  .wrapper {
    /*margin-left: 8px;*/
  }
  .slug {
    background-color: #fdfd96;
    padding: 3px 5px
  }
  .input {
    width: auto;
  }
  .url-wrapper {
    display: flex;
    align-items: center;
    height: 28px;
  }
</style>

<template>
  <div class="slug-widget">
    <div class="url-wrapper wrapper">
       <v-btn icon small
       @click="goTo"

        >
         <v-icon small>mdi-link</v-icon>
       </v-btn>
      <span class="root-url">{{url}}</span
      ><span class="subdirectory-url">/{{subdirectory}}/</span
      ><span class="slug" v-show="slug && !isEditing"><strong>{{slug}}</strong></span
      ><v-text-field class="ml-2"
      name="slug" v-show="isEditing" v-model="customSlug" @change="saveSlug"/>

      <v-btn x-small text v-show="!isEditing" @click.prevent="editSlug">{{$t('general.slug.edit')}}</v-btn>
      <v-btn x-small v-show="isEditing" @click.prevent="saveSlug">{{$t('general.slug.save')}}</v-btn>
      <v-btn color="yellow lighten-4" class="ml-1" x-small v-show="isEditing" @click.prevent="resetEditing">{{$t('general.slug.reset')}}</v-btn>
    </div>
  </div>
</template>

<script>

import { debounce } from 'vue-debounce'
import Slug from 'slug';
Slug.defaults.mode = 'rfc3986';

import { mapGetters } from 'vuex'

    export default {
      props: {
        url: {
          type: String,
          required: true
        },
        subdirectory: {
          type: String,
          required: true
        },
        title: {
          type: String,
          required: true
        }
      },
      data: function() {
        return {
          slug: this.setSlug(this.title),
          isEditing: false,
          customSlug: '',
          wasEdited: false,
          start: false
        }
      },
      computed:{
          ...mapGetters({
              token: 'auth/token',
          }),

      },      
      methods: {
        goTo(){
          let url = this.url+'/'+this.subdirectory+'/'+this.slug;
          window.open(url, '_blank');
        },
        editSlug: function() {
          this.customSlug = this.slug;
          this.$emit('edit', this.slug);
          this.isEditing = true;
        },
        saveSlug: function() {
          if (this.customSlug !== this.slug) this.wasEdited = true;
          this.setSlug(this.customSlug);
          this.$emit('slug-save', this.customSlug); //this.slug was prev...
          this.isEditing = false;
        },
        resetEditing: function() {
          this.setSlug(this.title);
          this.$emit('slug-reset', this.slug);
          this.wasEdited = false;
          this.isEditing = false;
        },
        setSlug: function(newVal, count = 0) {
          let slug = Slug(newVal + (count > 0 ? `-${count}` : ''));
          let vm = this;

          if (slug) {

            this.$http.get('api/company/unique', {
              params: {
                slug: slug
              }
            }).then(function (response) {
              if (response.data) {
                vm.slug = slug;
                vm.$emit('slug-changed', slug)
              } else {
                vm.setSlug(newVal, count+1)
              }
            }).catch(function (error) {
              console.log(error);
            });



          }

        }        
      },
      watch: {
        title: debounce(function() {
            if (this.wasEdited == false) this.setSlug(this.title);
            // run ajax to see if slug is unique
            // if not unique, customize the slug to make it unique
          }, 500)
      }
    }
</script>
